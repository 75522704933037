import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Slider from "react-slick"
import Heading from "../heading"
import BannerSearch from "./search-hero.webp"

const SearchHero = ({}) => {
  const settings = {
    autoplay: true,
    dots: true,
    infinite: true,
    fade: true,
    speed: 1000,
    lazyLoad: true,
    autoplaySpeed: 2500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <section className="hero custom-dots height-small">
      <Slider {...settings}>
        <div>
          <div className="hero-element">
            <img
              className="wideBanner"
              src={BannerSearch}
              width="1920"
              height="600"
            />
            <Container className="hero-contents">
              <Row className="justify-content-center alignCenter">
                <Col md={10}>
                  <Heading fontStyle={1} />
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </Slider>
    </section>
  )
}

export default SearchHero
