import { useIntl } from "gatsby-plugin-react-intl"
import { graphql, Link } from "gatsby"
import { generateUrlByLocale } from "../components/common/functions"
import SearchHero from "../components/common/search/searchHero"
import Seo from "../components/seo"
import Layout from "../components/layout"
import React from "react"
import { setSeoData } from "../services/seo.service"
import { useFlexSearch } from "react-use-flexsearch"
import { Col, Container, Row } from "react-bootstrap"
import Media from "../components/common/media"

export default function Search({ data, pageContext }) {
  const isSSR = typeof window === "undefined"
  const intl = useIntl()

  let params
  let query = ""

  if (!isSSR) {
    // Get query string from url
    params = new URLSearchParams(window.location.search.slice(1))
    query = params.get("q") || ""
  }

  const title =
    intl.formatMessage({ id: "page.search.seo.title" }) + " " + query
  const ogTitle =
    intl.formatMessage({ id: "page.search.seo.title" }) + " " + query

  const seoData = setSeoData({
    lang: intl.locale,
    title: title,
    field_seo: {
      description: intl.formatMessage({
        id: "page.search.seo.meta.description",
      }),
      title: title,
      og_description: intl.formatMessage({
        id: "page.search.seo.ogTags.description",
      }),
      og_image: intl.formatMessage({ id: "page.search.seo.ogTags.image" }),
      og_locale: intl.locale,
      og_title: ogTitle,
      og_type: intl.formatMessage({ id: "page.search.seo.ogTags.type" }),
    },
  })
  let index, store

  if (data.localSearchPages) {
    index = data.localSearchPages.index
    store = data.localSearchPages.store
  }

  const results = useFlexSearch(query, index, store)
  return (
    <Layout pageContext={pageContext}>
      {!isSSR && (
        <React.Suspense fallback="loading.... ">
          <Seo data={seoData} />
          <SearchHero />
          <section className="blk-search">
            <Container>
              <Row>
                <Col>
                  <div className="titleSearch">
                    {query ? (
                      <h1>
                        {intl.formatMessage({ id: "page.search.h1_results" })}
                      </h1>
                    ) : (
                      <h1>
                        {intl.formatMessage({ id: "page.search.h1_default" })}
                      </h1>
                    )}
                    {query ? <span>{`"${query}"`}</span> : ""}
                  </div>
                </Col>
              </Row>
            </Container>
            {results.length ? (
              <Container>
                <Row>
                  <Col>
                    <ul className="resultList">
                      {results
                        .filter(result => result.locale === intl.locale)
                        .map(result => (
                          <li key={result.id}>
                            {result.image && (
                              <div className="image-search">
                                <Link
                                  to={generateUrlByLocale(
                                    result.locale,
                                    result.path
                                  )}
                                >
                                  <Media media={result.image} />
                                </Link>
                              </div>
                            )}
                            <h2>
                              <Link
                                to={generateUrlByLocale(
                                  result.locale,
                                  result.path
                                )}
                              >
                                {result.title}
                              </Link>
                            </h2>
                          </li>
                        ))}
                    </ul>
                  </Col>
                </Row>
              </Container>
            ) : (
              <Container>
                <Row>
                  <Col>
                    <p>
                      {intl.formatMessage({ id: "page.search.no_results" })}
                    </p>
                  </Col>
                </Row>
              </Container>
            )}
          </section>
        </React.Suspense>
      )}
    </Layout>
  )
}

export const query = graphql`
  query {
    localSearchPages {
      index
      store
    }
  }
`
